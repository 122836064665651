.services-cont {
  text-align: center;
  h1 {
    color: antiquewhite;
  }
  > div {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    .services-tiles {
      display: flex;
      flex-wrap: wrap;
      flex-basis: 33.333333%;
      height: 125px;
      flex-direction: column;
      .services-tile-icon {
        font-size: 2em;
        padding-bottom: 0.5em;
        span:hover{
            font-size: 1.1em;
            cursor: pointer ;
        }
      }
    }
  }
}

@media only screen and (max-width: 680px) and (min-width: 500px) {
  .services-tiles {
    flex-basis: 50% !important;
  }
}
@media only screen and (max-width: 499px) {
  .services-tiles {
    flex-basis: 100% !important;
  }
}

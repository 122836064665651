.homepage-contents {
  color: antiquewhite;
  position: absolute;
  margin: 0 auto;
  top: 25%;
  width: 60%;
  left: 20%;
  h1 {
    color: antiquewhite;
  }
}
@media only screen and (max-width: 499px) {
  .homepage-contents {
    color: antiquewhite;
    position: absolute;
    margin: 0 auto;
    top: 25%;
    width: 80%;
    left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}

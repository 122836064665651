.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  align-items: center;
}
.loader-background {
  background: antiquewhite;
  opacity: 0.6;
  z-index: 3;
}
.loader-text{
    z-index: 4;
    font-size: 1.5em;
    color: black;
}
.contact {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: antiquewhite;
  h1 {
    color: antiquewhite;
  }
  > div {
    display: flex;
    flex-wrap: wrap;
    /* margin-right: -15px; */
    /* margin-left: -15px; */
    width: 100%;
    justify-content: center;
    margin-bottom: 2em;
  }
  .common-input-cls {
    outline: none;
    background: transparent;
    color: antiquewhite;
    border: none;
    border-bottom: 2px solid;
    font-size: 14px;
    margin-bottom: 2em;
  }
  .input-set-1 {
    outline: none;
    height: 36px;
    background: transparent;
    color: antiquewhite;
    border: none;
    border-bottom: 2px solid;
    flex: 0 0 40%;
    // max-width: 40%;
    position: relative;
    justify-content: space-between;
    margin: 0 3.5%;
  }
  .input-set-2 {
    width: 88%;
    height: 100px;
  }
  .submit-btn {
    margin-top: 2em;
    padding: 10px 20px;
    background: antiquewhite;
    cursor: pointer;
    outline: none;
    border: 2px solid;
    color: rgb(73, 70, 64);
    transition: 0.3s background ease;
    &:hover {
      background: rgb(73, 70, 64);
      border: 2px solid rgb(73, 70, 64);
      color: antiquewhite;
    }
  }
}
@media only screen and (max-width: 499px) {
  .contact {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: antiquewhite;
    > div {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      margin-bottom: 2em;
      .input-set-1 {
        flex-basis: 88% !important;
      }
    }
  }
}

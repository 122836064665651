.aboutus {
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  p {
    // flex: 0 0 49%;
    // max-width: 49%;
    margin-top: 0 !important;
    position: relative;
    width: 100%;
    // padding-right: 15px;
    padding-left: 1%;
    h1 {
      margin-top: 0 !important;
      color: antiquewhite;
    }
    a {
      display: inline-block;
      text-decoration: none;
      text-decoration-skip-ink: auto;
      position: relative;
      transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
      color: #f79a98;
    }
    a::after {
      content: "";
      display: block;
      width: 0px;
      height: 1px;
      position: relative;
      bottom: 0px;
      background-color:  #f79a98;
      transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 0.5;
    }
    a:hover::after,
    p > a:focus::after,
    p > a:active::after {
      width: 100%;
    }
  }
}
.suman-img {
  //   flex: 0 0 49%;
  //   max-width: 49%;
  position: relative;
  display: flex;
  padding-right: 1%;
  //   padding-left: 15px;
  img {
    position: relative;
    width: 100%;
    width: 400px;
    height: 450px;
    z-index: 1;
    -webkit-box-shadow: 0 0 85px 0 rgb(0 0 0 / 14%);
    box-shadow: 0 0 85px 0 rgb(0 0 0 / 14%);
  }
}

@media only screen and (max-width: 720px) {
  .suman-img {
    flex-direction: column;
    img {
      position: relative;
      width: 85%;
      z-index: 1;
      box-shadow: 0 0 85px 0 rgb(0 0 0 / 14%);
      margin: 0 auto;
      height: auto;
    }
  }
}

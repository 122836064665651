.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  // background: url(./images/5652030.jpg);
  background: #000000c4;
  // height: 100%;
  // width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: antiquewhite;
}

.app-content {
  // background: url(./images/5652030.jpg);
  height: calc(100vh - 55px - 40px);
  overflow-y: auto;
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
  color: antiquewhite;
  // padding: 0 10px;
  width: 100%;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  // animation: article-load 1s ease-in 1s;
}
.dropdownOpenCss {
  width: calc(100vw - 200px);
  left: 200px;
  // transition: width 0.3s ease 0s, left 0.3s ease 0s;
}

.dropdownOpenCss2 {
  left: 0px;
}
footer {
  height: 40px;
  background: antiquewhite;
  color: #161212;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
  background: linear-gradient(
    180deg,
    antiquewhite 0%,
    rgb(182, 172, 159) 50%,
    antiquewhite 100%
  );
  // height: 40px;
  // background: antiquewhite;
  // color: #161212;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // font-size: 12px;
  // z-index: 2;
  // background: linear-gradient(
  //   180deg,
  //   antiquewhite 0%,
  //   rgb(182, 172, 159) 50%,
  //   antiquewhite 100%
  // );
}

@media only screen and (min-width: 700px) {
  .app-content {
    position: absolute;
    top: 52px;
    // left: 200px;
    // width: calc(100vw - 200px);
  }
  footer {
    position: absolute;
    bottom: 0;
    width: 100vw;
  }
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bcbcbc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.download-btn {
  justify-content: right;
  display: flex;
  margin-right: 15px;
  margin-top: 10px;
  margin-bottom: 15px;
  text-decoration: unset;
  span {
    border: 1px solid;
    padding: 5px 7px;
    border-radius: 4px;
    background: antiquewhite;
    color: #161212;
    z-index: 2;
  }
  &:hover {
    cursor: pointer;
  }
}

// .resume{
//   animation: article-load 0.3s ease-in 1s;
// }
// .aboutus {
//   animation: article-load 0.3s ease-in 1s;
// }
// .contact {
//   animation: article-load 0.3s ease-in 1s;
// }

// @keyframes article-load {
//   0% {
//     transform: translateY(-30px);
//     opacity: 0;
//   }
//   100% {
//     transform: translateY(0);
//     opacity: 1;
//   }
// }
